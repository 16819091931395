@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');

html, body, head, * {
  font-family: 'Rubik', sans-serif;
}

html, body {
  overflow-x: hidden !important;
} 
* {
  font-family: 'Rubik', sans-serif !important;
}

.MuiButtonBase-root, .MuiPaper-root {
  font-family: 'Rubik', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#date-picker-dialog {
  text-align: right !important;
}

.MuiInputBase-input, .MuiInput-input {
  text-align: right !important;
}

.styles_ticker__8iZE9 {
  background-color: transparent !important;
}

.styles_ticker-list__1YeNe {
  color: black !important;
  font-size: 1.5em !important;
}

/* ticker */
@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  5% {
    -webkit-transform: translate3d(-5%, 0, 0);
    transform: translate3d(-5%, 0, 0);
  }
  10% {
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }
  15% {
    -webkit-transform: translate3d(-15%, 0, 0);
    transform: translate3d(-15%, 0, 0);
  }
  20% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
  }
  25% {
    -webkit-transform: translate3d(-25%, 0, 0);
    transform: translate3d(-25%, 0, 0);
  }
  30% {
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
  35% {
    -webkit-transform: translate3d(-35%, 0, 0);
    transform: translate3d(-35%, 0, 0);
  }
  40% {
    -webkit-transform: translate3d(-40%, 0, 0);
    transform: translate3d(-40%, 0, 0);
  }
  45% {
    -webkit-transform: translate3d(-45%, 0, 0);
    transform: translate3d(-45%, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
  55% {
    -webkit-transform: translate3d(-55%, 0, 0);
    transform: translate3d(-55%, 0, 0);
  }
  60% {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  65% {
    -webkit-transform: translate3d(-65%, 0, 0);
    transform: translate3d(-65%, 0, 0);
  }
  70% {
    -webkit-transform: translate3d(-70%, 0, 0);
    transform: translate3d(-70%, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-75%, 0, 0);
    transform: translate3d(-75%, 0, 0);
  }
  80% {
    -webkit-transform: translate3d(-80%, 0, 0);
    transform: translate3d(-80%, 0, 0);
  }
  85% {
    -webkit-transform: translate3d(-85%, 0, 0);
    transform: translate3d(-85%, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-90%, 0, 0);
    transform: translate3d(-90%, 0, 0);
  }
  95% {
    -webkit-transform: translate3d(-95%, 0, 0);
    transform: translate3d(-95%, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  5% {
    -webkit-transform: translate3d(-5%, 0, 0);
    transform: translate3d(-5%, 0, 0);
  }
  10% {
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }
  15% {
    -webkit-transform: translate3d(-15%, 0, 0);
    transform: translate3d(-15%, 0, 0);
  }
  20% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
  }
  25% {
    -webkit-transform: translate3d(-25%, 0, 0);
    transform: translate3d(-25%, 0, 0);
  }
  30% {
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
  35% {
    -webkit-transform: translate3d(-35%, 0, 0);
    transform: translate3d(-35%, 0, 0);
  }
  40% {
    -webkit-transform: translate3d(-40%, 0, 0);
    transform: translate3d(-40%, 0, 0);
  }
  45% {
    -webkit-transform: translate3d(-45%, 0, 0);
    transform: translate3d(-45%, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
  55% {
    -webkit-transform: translate3d(-55%, 0, 0);
    transform: translate3d(-55%, 0, 0);
  }
  60% {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  65% {
    -webkit-transform: translate3d(-65%, 0, 0);
    transform: translate3d(-65%, 0, 0);
  }
  70% {
    -webkit-transform: translate3d(-70%, 0, 0);
    transform: translate3d(-70%, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-75%, 0, 0);
    transform: translate3d(-75%, 0, 0);
  }
  80% {
    -webkit-transform: translate3d(-80%, 0, 0);
    transform: translate3d(-80%, 0, 0);
  }
  85% {
    -webkit-transform: translate3d(-85%, 0, 0);
    transform: translate3d(-85%, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-90%, 0, 0);
    transform: translate3d(-90%, 0, 0);
  }
  95% {
    -webkit-transform: translate3d(-95%, 0, 0);
    transform: translate3d(-95%, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-95%, 0, 0);
    transform: translate3d(-95%, 0, 0);
  }
}
.ticker-wrap {
  /* position: fixed; */
  width: 100%;
  overflow: hidden;
  /* height: 20rem; */
  padding-left: 100%;
  height: 3em;
}

.ticker {
  display: inline-block;
  height: 20rem;
  white-space: nowrap;
  padding-right: 100%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 60s;
  animation-duration: 60s;
}
.ticker__item {
  direction: rtl;
  display: inline-block;
  font-size: 1.5rem;
}

input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #1D63DC;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    color: white;
    background-color: #1D63DC;
}


/* Select Search */
/**
 * Main wrapper
 */
.select-search {
    width: 100%;
    position: relative;
    text-align: right !important;
    direction: rtl !important;
    box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
    text-align: right !important;
    direction: rtl !important;
}

/**
 * Value wrapper
 */
.select-search__value {
    position: relative;
    z-index: 1;
    text-align: right !important;
    direction: rtl !important;
}

.select-search__value::after {
    /* content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px; */
}

/**
 * Input
 */
.select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: 1px solid transparent;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    outline: none;
    /* font-family: 'Noto Sans', sans-serif; */
    font-size: 1em;
    text-align: right;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
    text-align: right !important;
    direction: rtl !important;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance:none;
    text-align: right !important;
    direction: rtl !important;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
    text-align: right !important;
    direction: rtl !important;
}

/**
 * Options wrapper
 */
.select-search__select {
    background: #fff;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    text-align: right !important;
    text-align: right !important;
    direction: rtl !important;
}

/**
 * Options
 */
.select-search__options {
    list-style: none;
    text-align: right !important;
    direction: rtl !important;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
    text-align: right !important;
    direction: rtl !important;
}

/**
 * Option
 */
.select-search__option {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    text-align: right !important;
    direction: rtl !important;
}

.select-search--multiple .select-search__option {
    height: 48px;
    text-align: right !important;
    direction: rtl !important;
}

.select-search__option.is-selected {
    background: #2FCC8B;
    color: #fff;
    text-align: right !important;
    direction: rtl !important;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #2eb378;
    color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #2FCC8B;
}


.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

/* board styling */
.bottomleft {
  position: absolute;
  bottom: 0;
  left: 0.5em;
  width: 400px;
  font-weight: bold;
  color: #fff;
}
.text-block {
  position: absolute;
  bottom: 60px;
  right: 5px;
  background-color: green;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}
